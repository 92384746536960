import React from "react";
import { useDispatch } from "react-redux";
import { Paper } from "@mui/material";
// import GoBackButton from "./GoBackButton";
import VerifyOptions from "./VerifyOptions";
import Authenticator from "./Authenticator";
import { sendVerifyCodeToEmail } from "../../../services/auth";

const verifyOptions = { email: "email", ga: "ga" };

function Authenticators(props: any) {
  const dispatch = useDispatch();

  const { user, verifyState, setVerifyState } = props;

  async function handleVerifyOption(data) {
    const option = data.option;
    const userObject = { id: user?.user?.id };
    if (option === verifyOptions.email) {
      await dispatch(sendVerifyCodeToEmail(userObject));
    }
    setVerifyState(data.option);
  }

  function resendVerificationCodeToEmail() {
    const userObject = { id: user?.user?.id };
    dispatch(sendVerifyCodeToEmail(userObject, "resend"));
  }

  return (
    <Paper elevation={3}>
      {verifyState ? (
        <Authenticator
          type={verifyState}
          setVerifyState={setVerifyState}
          user={user}
          handleResendVerificationCodeToEmail={resendVerificationCodeToEmail}
        />
      ) : (
        <VerifyOptions handleVerifyOption={handleVerifyOption} />
      )}
    </Paper>
  );
}

export default Authenticators;
