import { store } from "..";

const getTheUserState = () => {
  const appState = store.getState("user");
  const user = appState.auth?.user;

  return user;
};

export default getTheUserState;
