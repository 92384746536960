import React, { useEffect, useState } from "react";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { getMaintanenceStatus } from "../../services/serverNotifications";

function MaintenanceBanner() {
  const [serverMaintanenceStatus, setServerMaintanenceStatus] = useState([]);

  async function getMaintanenceServerStatus() {
    await getMaintanenceStatus()
      .then((data) => setServerMaintanenceStatus(data?.maintanence[0]))
      .catch((error) => {
        if (
          error.response.status === 504 ||
          error.response.status === 502 ||
          (error.response && error.response.status === 504)
        ) {
          setServerMaintanenceStatus("error");
        }
      });
  }

  function displayBanner() {
    let isBannerActive;
    if (serverMaintanenceStatus?.active || serverMaintanenceStatus === "error") {
      isBannerActive = "block";
    } else {
      isBannerActive = "none";
    }

    return isBannerActive;
  }

  useEffect(() => {
    getMaintanenceServerStatus();
  }, []);

  let content;

  if (serverMaintanenceStatus !== "error") {
    content = serverMaintanenceStatus?.title;
  } else {
    content =
      "Maintenance work will be carried out on the system in the next one hour. System disruptions may occur during this time.";
  }

  const bannerStyles = {
    bannerWrapper: {
      backgroundColor: "#ed6c02",
      fontFamily: "Roboto",
      padding: "2vh 0vh",
      display: displayBanner(),
    },
    title: {
      color: "white",
      fontSize: "23px",
    },
  };

  return (
    <div style={bannerStyles.bannerWrapper}>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <div style={{ display: "flex", alignItems: "center", fontWeight: "500" }}>
          <WarningAmberIcon sx={{ color: "white", paddingRight: "15px" }} />
          <p style={bannerStyles.title}>System information:</p>
        </div>
        <span style={bannerStyles.title}>{content}</span>
      </div>
    </div>
  );
}

export default MaintenanceBanner;
